<!-- CustomDropdown.vue -->
<template>
  <div class="dropdown">
    <button @click="toggleDropdown" class="dropdown-button">
      <span class="lang">
      <img :src="selectedImage" alt="Language Icon">
        {{ selectedText }}
      </span>
      <span :class="{'arrow-up': dropdownOpen, 'arrow-down': !dropdownOpen}"></span>
    </button>
    <transition name="slide">
      <div v-if="dropdownOpen" class="dropdown-menu">
        <div
            v-for="(option, index) in options"
            :key="index"
            @click="selectOption(option)"
            class="dropdown-item"
        >
          <img :src="option.image" alt="Language Icon"> {{ option.text }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  data() {
    return {
      dropdownOpen: false,
      selectedImage: require('../assets/images/me.png'),
      selectedText: 'Crnogorski',
      options: [
        {value: 'en', text: 'English', image: require('../assets/images/eng.png')},
        {value: 'me', text: 'Crnogorski', image: require('../assets/images/me.png')}
      ]
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectOption(option) {
      this.selectedImage = option.image;
      this.selectedText = option.text;
      this.dropdownOpen = false;
      this.$i18n.locale = option.value;
      // You can emit an event or update a model here
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-button {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
    min-width: 150px;
    color: white;

    img {
      margin-right: 5px;
      width: 16px;
      align-self: baseline;
    }

    .arrow-down {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-bottom: 5px;
      transform: rotate(45deg);
      margin-left: 10px;
      transition: transform 0.3s ease;
    }

    .arrow-up {
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-top: 5px;
      transform: rotate(-135deg);
      margin-left: 10px;
      transition: transform 0.3s ease;
    }

    .lang {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 16px;
    }
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transform-origin: top;
    transition: transform .4s ease-in-out;
    overflow: hidden;

  }

  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: black;

    img {
      margin-right: 5px;
      width: 16px;
      align-self: baseline;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.4s ease-in-out;
}

.slide-enter, .slide-leave-to {
  transform: scaleY(0);
}

.slide-enter-to, .slide-leave {
  transform: scaleY(1);
}
</style>
